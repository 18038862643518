<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <table class="table border mb-0">
        <thead class="text-center bg-success text-white">
          <tr>
            <th class="border align-middle" rowspan="3">Critério</th>
            <th class="border align-middle" rowspan="3">Situação</th>
            <th class="border align-middle" colspan="5">Potencial de Risco</th>
          </tr>
          <tr>
            <th class="border align-middle">Muito baixo</th>
            <th class="border align-middle">Baixo</th>
            <th class="border align-middle">Neutro</th>
            <th class="border align-middle">Alto</th>
            <th class="border align-middle">Muito alto</th>
          </tr>
          <tr>
            <th class="border align-middle">1</th>
            <th class="border align-middle">2</th>
            <th class="border align-middle">3</th>
            <th class="border align-middle">4</th>
            <th class="border align-middle">5</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in form">
            <tr v-bind:key="'fonte-tr-' + i">
              <td>{{ item.descricao_criterio }}</td>
              <td>{{ item.descricao }}</td>
              <td
                class="text-center"
                v-for="i in 5"
                :key="
                  'potencial_risco-' + item.idrisco_plano_investimento + '-' + i
                "
                :title="options[i - 1]"
              >
                <b-form-radio
                  size="lg"
                  v-model="item.potencial_risco"
                  :value="i"
                  :name="'potencial_risco-' + item.idrisco_plano_investimento"
                  :required="!finalizado"
                  :disabled="finalizado"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipPotencialRiscoForm",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-tasks",
        title: "Avaliação de Risco",
        description: `Avalie cada pergunta e responda da forma que mais reflita
          a realidade que poderá encontrar quando seu projeto estiver
          funcionando. As suas respostas serão transformadas em uma pontuação
          que definirá o potencial de risco do projeto que está analisando.`
      },
      form: [],
      options: ["Muito baixo", "Baixo", "Neutro", "Alto", "Muito alto"],
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/potencial_risco`;
    },
    manual() {
      return {
        session: "potencial_risco",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    nota_total() {
      return this.form.reduce((acc, cur) => {
        acc += cur.peso * (cur.potencial_risco ?? 0);
        return acc;
      }, 0);
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData("")];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].risco ?? [];
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
